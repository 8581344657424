<template>
  <layout>
    <div class="row row-cards" v-if="is_superuser">
      <div class="col-9">
        <router-view 
          :rows="rows"
          :paginator="paginator"
          @reloadTable="reloadTable"
          @setPage="setPage"
          @setOrder="setOrder"
          @saveXLS="saveXLS"
          @print="print"
        ></router-view>
      </div>
      <div class="col-3">
        <div class="card" style="margin-bottom: 1em;">
          <div class="card-body">
            <p>Явка: <b>{{ paginator.visits_persent }}%</b> ({{ paginator.visits_cnt }} / {{ paginator.total }})</p>
          </div>
        </div>
        <SearchPanel @searchFormFilter="queryFitler"></SearchPanel>
      </div>
    </div>
    <div class="row row-cards" v-if="!is_superuser">
      <div class="card-body">
              <div 
                v-for="people in numbers" :value="people.id" :key="people.id" style="float: left; width: 90px;margin: 2px 2px;"
              >
                <a @click="updateVisit(people.id)" class="btn w-100" :class="{'btn-success': people.visit}">
                  {{ people.num }}
                </a>
              </div>
          </div>
    </div>
  </layout>
</template>



<script>
import SearchPanel from "./SearchPanel.vue";
import Layout from "../../layouts/Base.vue";

export default {
  components: {
    SearchPanel,
    Layout,
  },
  data: function () {
    return {
      rows: [],
      paginator: {},
      query_search: "",
      query_page: "",
      query_order: "",
      base_url: "/uik/peoples/list/",
      is_superuser: localStorage.getItem("is_superuser") == 'true',
      numbers: [],
      visits: {},
    };
  },
  methods: {
    queryFitler(params) {
      var search_params = new URLSearchParams(params);
      this.query_page = "";
      this.query_search = search_params.toString();
      this.reloadTable();
    },
    setPage(page) {
      this.query_page = "page=" + page;
      this.reloadTable();
    },
    setOrder(ordering) {
      this.query_order = "ordering=" + ordering;
      this.reloadTable();
    },
    reloadTable() {
      var source = this.base_url + "?";
      if (this.query_search) source += "&" + this.query_search;
      if (this.query_page) source += "&" + this.query_page;
      if (this.query_order) source += "&" + this.query_order;
      fetch(source)
        .then((response) => response.json())
        .then((data) => {
          this.rows = data.results;
          this.paginator = data.meta.paginator;
        });
    },
    saveXLS(){
      location.href = this.base_url + "?format=xlsx&" + this.query_search;
    },
    print(){
      window.open(this.base_url + "?format=print&" + this.query_search);
    },
    reloadNumbers(){
    fetch("/uik/peoples/numbers/")
      .then((response) => response.json())
      .then((data) => {
        this.numbers = data;
      });
    },
    async updateVisit(id){
      const response = await fetch("/uik/peoples/numbers/" + id + "/", {
        method: "post",
        credentials: "include",
      });
      if (response.ok) {
        this.reloadNumbers();
      }
    }
  },
  mounted() {
    this.reloadTable();
    this.reloadNumbers();

  },
};
</script>