<template>
  <layout>

    <div class="row row-cards">
      <div class="col-4">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              Общая явка
              <span class="pull-right text-info">{{ updated }}</span>
            </div>
            <VueApexCharts type="pie" :options="options" :series="series"></VueApexCharts>
          </div>
        </div>
      </div>
      <div class="col-8">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              Заместители
              <span class="pull-right text-info" style="font-size: 0.875em;">
                Всего избирателей: <b>{{ total }}</b>,
                проголосовали: <b>{{ visits }} ({{ visits_percent }}%)</b>,
                не проголосовали: <b>{{ not_visits }} ({{ not_visits_percent }}%)</b>,
              </span>
            </div>
            <div class="table-responsive">
              <table class="table card-table table-vcenter text-nowrap datatable">
                <thead>
                  <tr>
                    <th>ФИО</th>
                    <th class="text-end">Всего</th>
                    <th class="text-end">Пришли</th>
                    <th class="text-end">%</th>
                  </tr>
                </thead>
                  <tbody v-for="zam in zams" :value="zam.id" :key="zam.id">
                    <tr class="zams">
                      <td>{{ zam.fio }}</td>
                      <td class="text-end">{{ zam.total }}</td>
                      <td class="text-end">{{ zam.visits }}</td>
                      <td class="text-end">{{ zam.visits_percent }}</td>
                    </tr>
                    <tr v-for="org in zam.orgs" :value="org.id" :key="org.id" class="orgs">
                      <td>{{ org.name }}</td>
                      <td class="text-end">{{ org.total }}</td>
                      <td class="text-end">{{ org.visits }}</td>
                      <td class="text-end">{{ org.visits_percent }}</td>
                    </tr>

                  </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>


  </layout>
</template>


<script>
import Layout from "../../layouts/Base.vue"
import VueApexCharts from 'vue-apexcharts'



export default {
  components: {
    Layout, VueApexCharts
  },
  data: function() {
    return {
      options: {
        labels: ["Проголосовало:", "Не проголосовало:"],
      },
      series: [0, 100],
      zams: [],
      updated: "",
      total: 0,
      visits: 0,
      visits_percent: 0,
      not_visits: 0,
      not_visits_percent: 0,
    }
  },
  methods: {
    updateChart() {
      fetch("/uik/report/total/")
        .then((response) => response.json())
        .then((data) => {
          let visits = data["visits"],
              visits_percent = data["visits_percent"],
              not_visits = data["not_visits"],
              not_visits_percent = data["not_visits_percent"];
          this.total = visits + not_visits;
          this.visits = visits;
          this.visits_percent = visits_percent;
          this.not_visits = not_visits;
          this.not_visits_percent = not_visits_percent;
          this.series = [visits_percent, not_visits_percent];
          this.updated = data["updated"];
          this.options = {
            labels: [
              "Проголосовало: " + visits + " (" + visits_percent + "%)",
              "Не проголосовало: " + not_visits + " (" + not_visits_percent + "%)"
            ],
          }
          this.zams = data["zams"]
        });
    }
  },
  mounted(){
    this.updateChart();
    setInterval(this.updateChart, 15000);
  }

}

</script>


<style>
 tr.zams {font-weight: bold;}
 tr.orgs {font-size: 0.75em;}
 td {border-bottom: 1px solid #eee !important;}
 tr.orgs td:first-child {padding-left: 4em}
</style>