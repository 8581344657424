<template>
  <div id="app" class="page">
    <Header name="УИК"></Header>
    <Navbar></Navbar>
    <div class="content">
      <div class="container-fluid">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Navbar from "../components/Navbar.vue";

export default {
  components: {
    Header,
    Navbar,
  },
};
</script>
