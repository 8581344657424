<template>
  <header class="navbar navbar-expand-md navbar-light d-print-none">
    <div class="container-fluid">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-menu">
        <span class="navbar-toggler-icon"></span>
      </button>
      <h1 class="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3">
        {{ name }}
      </h1>
      <div class="navbar-nav flex-row order-md-last">

        <div class="nav-item d-none d-md-flex me-3">
          <select class="form-select" v-model="current_election" @change="setElection()">
            <option value=0>УИК</option>
            <option v-for="e in election_choices" :key="e.id" :value="e.id">{{ e.name }}</option>
          </select>
        </div>

        <div class="nav-item d-none d-md-flex me-3" v-if="uik_choices.length">
          <select class="form-select" v-model="current_uik" @change="setUik()">
            <option v-for="e in uik_choices" :key="e.id" :value="e.id">{{ e.num }}</option>
          </select>
        </div>

        <div class="nav-item dropdown d-none d-md-flex me-3">
          <span>{{ ini }}</span>
        </div>

        <div class="nav-item dropdown d-none d-md-flex me-3">
          <a href="#" v-on:click="logout">Выйти</a>
        </div>
      </div>
    </div>
  </header>
</template>

<script>

function setCookie(name,value,days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

export default {
  name: "Header",
  data: () => ({
    ini: localStorage.getItem("userIni"),
    election_choices: [],
    current_election: 0,
    uik_choices: [],
    current_uik: 0,
  }),
  props: {
    name: String,
  },
  methods: {
    async logout() {
      const response = await fetch("/auth/logout/", {
        method: "POST",
      });
      if (response.ok) {
        localStorage.removeItem("userIni");
        localStorage.removeItem("is_superuser");
        localStorage.removeItem("authStatus");
        this.$router.push({ name: "login" }).catch(() => {});
      }
    },
    getElectionChoices() {
      fetch("/uik/election/list/")
        .then((response) => response.json())
        .then((data) => {
          this.election_choices = data.results;
          this.current_election = getCookie("election") || data.results[0].id
        });
    },
    getUikChoices() {
      fetch("/uik/list/")
        .then((response) => response.json())
        .then((data) => {
          this.uik_choices = data.results;
          this.current_uik = getCookie("uik")
        });
    },
    setUik(){
      if(this.current_election){
        setCookie("uik", this.current_uik, 30)
        window.location.reload()
      }
    },
    setElection(){
      if(this.current_election){
        setCookie("election", this.current_election, 30)
        window.location.reload()
      }
    }
  },
  mounted() {
    this.getElectionChoices()
    this.getUikChoices()
  },
};
</script>