<template>
  <div class="card" v-on:keyup.esc="showModal = false">
    <ul class="nav nav-tabs" data-bs-toggle="tabs">
      <li class="nav-item">
        <a href="" class="nav-link active" data-bs-toggle="tab">
          <span class="nav-link-icon d-md-none d-lg-inline-block">
            <i class="fa fa-list"></i>
          </span>
          <span class="nav-link-title"> Список </span>
        </a>
      </li>
      <li class="nav-item ms-auto right-btns">
        <p class="btn btn-info" @click="$emit('print')">
          <i class="fa fa-print"></i> Печать
        </p>
        <p class="btn btn-info" @click="$emit('saveXLS')">
          <i class="fa fa-file-excel-o"></i> XLS
        </p>
      </li>
    </ul>

    <div class="table-responsive">
      <table class="table card-table table-vcenter text-nowrap table-hover datatable">
        <thead>
          <tr>
            <th class="text-center" width="100">
              <TableOrderLink field="num" :ordering="ordering" @change_ordering="change_ordering">№ строки</TableOrderLink>
            </th>
            <th class="text-center" width="100">
              <TableOrderLink field="uik__num" :ordering="ordering" @change_ordering="change_ordering">УИК</TableOrderLink>
            </th>
            <th class="text-center" width="200">
              <TableOrderLink field="home__city__name" :ordering="ordering" @change_ordering="change_ordering">Город</TableOrderLink>
            </th>
            <th width="200">
              <TableOrderLink field="home__street__name" :ordering="ordering" @change_ordering="change_ordering">Улица</TableOrderLink>
            </th>
            <th>
              <TableOrderLink field="home__num_int" :ordering="ordering" @change_ordering="change_ordering">Дом</TableOrderLink>
            </th>
            <th>
              <TableOrderLink field="first_name" :ordering="ordering" @change_ordering="change_ordering">Фамилия</TableOrderLink>
            </th>
            <th>
              <TableOrderLink field="name" :ordering="ordering" @change_ordering="change_ordering">Имя</TableOrderLink>

            </th>
            <th>Отчество</th>
            <th width="100">
              <TableOrderLink field="org__name" :ordering="ordering" @change_ordering="change_ordering">Организация</TableOrderLink>
            </th>
            <th>
              <TableOrderLink field="org__zam__fio" :ordering="ordering" @change_ordering="change_ordering">Зам. Главы</TableOrderLink>
            </th>
            <th>
              <TableOrderLink field="org__mobilizer" :ordering="ordering" @change_ordering="change_ordering">Мобилизатор</TableOrderLink>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="row in rows"
            v-bind:key="row.id"
            :class="{visited: row.visited}"
          >
            <td class="text-center">{{ row.num }}</td>
            <td class="text-center">{{ row.uik }}</td>
            <td class="text-center">{{ row.city }}</td>
            <td>{{ row.street }}</td>
            <td class="text-end">{{ row.home }}</td>
            <td>{{ row.first_name }}</td>
            <td>{{ row.name }}</td>
            <td>{{ row.patronymic }}</td>
            <td>{{ row.org && row.org.name }}</td>
            <td>{{ row.org && row.org.zam }}</td>
            <td>{{ row.org && row.org.mobilizer }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <Paginator :paginator="paginator"></Paginator>
    <Form v-if="showModal" @close="hideModalForm" :source="modalSource"></Form>
  </div>
</template>

<script>
import Form from "./Form.vue";
import Paginator from "../../components/Paginator.vue";
import TableOrderLink from "../../components/TableOrderLink.vue";

export default {
  name: "DataTable",
  data: function () {
    return {
      showModal: false,
      modalSource: "",
      ordering: "first_name",
    };
  },
  props: {
    rows: Array,
    paginator: Object,
  },
  components: {
    Form,
    Paginator,
    TableOrderLink,
  },
  methods: {
    change_ordering(field) {
      if(this.ordering == field){
        this.ordering = "-" + field
      } else {
        this.ordering = field
      }
      this.$emit("setOrder", this.ordering);
    }
  },
};
</script>

<style>
.visited {
  text-decoration: line-through;
}
</style>