<template>
  <div class="card-footer">
    <p class="m-0 text-muted pull-left">
      на странице <span>{{ paginator.show }}</span> из <span>{{ paginator.total }}</span> записей
    </p>
    <p class="m-0 text-muted pull-right" v-if="paginator.total_sum">
      Итого: <strong>{{ paginator.total_sum }}</strong>
    </p>
    <div v-if="paginator.has_next | paginator.has_previous">
      <nav aria-label="">
        <ul class="pagination justify-content-center">
          <li class="page-item" v-bind:class="{ disabled: !paginator.has_previous }">
            <p class="page-link" v-on:click="set_page(1)">Первая</p>
          </li>
          <li class="page-item" v-bind:class="{ disabled: !paginator.has_previous }">
            <p class="page-link" v-on:click="set_page(paginator.links.previous)">&#171;</p>
          </li>

          <li class="page-item" v-for="page in paginator.pages_visible" v-bind:key="page" v-bind:class="{ active: page == paginator.page }">
            <p class="page-link" class-active="active" v-on:click="set_page(page)">{{ page }}</p>
          </li>

          <li class="page-item" v-bind:class="{ disabled: !paginator.has_next }">
            <p class="page-link" v-on:click="set_page(paginator.links.next)">&#187;</p>
          </li>
          <li class="page-item" v-bind:class="{ disabled: !paginator.has_next }">
            <p class="page-link" v-on:click="set_page(paginator.last_page)">Последняя</p>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    paginator: Object,
  },
  methods: {
    set_page(page) {
      this.$parent.$emit("setPage", page);
    },
  },
};
</script>