<template>
  <a href="#" @click.prevent="change_ordering()">
  <slot></slot>
    <span v-show="ordering == field">
      <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><polyline points="6 9 12 15 18 9" /></svg>
    </span>
    <span v-show="ordering == '-' + field">
      <svg xmlns="http://www.w3.org/2000/svg" class="icon table-ordering-icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><polyline points="6 15 12 9 18 15" /></svg>
    </span>
  </a>

</template>

<script>

export default {
  props: {
    field: String,
    ordering: String,
  },
  methods: {
    change_ordering() {
      this.$emit("change_ordering", this.field);
    },
  }
};
</script>

<style type="text/css">
  svg.table-ordering-icon {width: 14px; height: 14px; margin-left: 2px;}
</style>