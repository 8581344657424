<template>
  <div class="modal" tabindex="-1" role="dialog" v-on:keyup.ctrl.enter="save()">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Перемещение лимитов</h5>
          <p @click="$emit('close')" title="Закрыть (Esc)">
            <router-link type="button" class="btn-close" :to="{ name: 'corrections' }"></router-link>
          </p>
        </div>
        <div class="modal-body">

          <div v-for="item in formError.non_field_errors" :key="item" class="text-danger mb-3">
            {{ item }}
          </div>

          <div class="row mb-3">
            <div class="col-lg-6">
              <label class="form-label">Дата</label>
              <date-picker v-model="form.date" format='DD.MM.YYYY' valueType="format" :class="{ 'is-invalid': formError.date }"></date-picker>
              <fieldErrors v-bind:errors="formError.date"></fieldErrors>
            </div>
            <div class="col-lg-6">
              <label class="form-label">Сумма</label>
              <input type="number" :class="{ 'is-invalid': formError.total }" class="form-control price-input" v-model="form.total" />
              <fieldErrors v-bind:errors="formError.total"></fieldErrors>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-lg-6">
              <label class="form-label">Организация</label>
              <select :class="{ 'is-invalid': formError.org }" class="form-select" v-model="form.org_from" @change="update_limit_from_choices()">
                <option v-for="org in form.org_choices" :value="org.id" :key="org.id">
                  {{ org.name }}
                </option>
              </select>
              <fieldErrors v-bind:errors="formError.org_from"></fieldErrors>
            </div>
            <div class="col-lg-6">
              <label class="form-label">Переместить с КБК</label>
              <select :class="{ 'is-invalid': formError.limit_from }" class="form-select" v-model="form.limit_from">
                <option v-for="limit_from in limit_from_choices" class="text-monospace" :value="limit_from.id" :key="limit_from.id">
                  {{ limit_from.kbk }}
                </option>
              </select>
              <fieldErrors v-bind:errors="formError.limit_from"></fieldErrors>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-lg-6">
              <label class="form-label">Организация</label>
              <select :class="{ 'is-invalid': formError.org_to }" class="form-select" v-model="form.org_to" @change="update_limit_to_choices()">
                <option v-for="org in form.org_choices" :value="org.id" :key="org.id">
                  {{ org.name }}
                </option>
              </select>
              <fieldErrors v-bind:errors="formError.org_to"></fieldErrors>
            </div>
            <div class="col-lg-6">
              <label class="form-label">Переместить на КБК</label>
              <select :class="{ 'is-invalid': formError.limit_to }" class="form-select" v-model="form.limit_to">
                <option v-for="limit_to in limit_to_choices" class="text-monospace" :value="limit_to.id" :key="limit_to.id">
                  {{ limit_to.kbk }}
                </option>
              </select>
              <fieldErrors v-bind:errors="formError.limit_to"></fieldErrors>
            </div>
          </div>

        </div>

        <div class="modal-footer">
          <p @click="del()" title="Удалить (Esc)" class="me-auto">
            <a class="btn"><i class="fa fa-times"></i> Удалить </a>
          </p>
          <p @click="$emit('close')" title="Закрыть (Esc)">
            <router-link title="Сохранить (Ctrl+Enter)" class="btn" :to="{ name: 'corrections' }"> Закрыть </router-link>
          </p>
          <p @click="save()">
            <router-link title="Сохранить (Ctrl+Enter)" class="btn btn-primary ms-auto" :to="{ name: 'corrections' }">
              <i class="icon fa fa-save"></i> Сохранить
            </router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import fieldErrors from "../../components/fieldErrors.vue";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ru';

export default {
  name: "Form",

  components: {
    fieldErrors, DatePicker,
  },

  data: function () {
    return {
      form: {},
      formError: {},
      limit_from_choices: [],
      limit_to_choices: [],
    };
  },
  props: {
    source: String,
  },
  async mounted() {
    const response = await fetch(this.source);
    if (response.ok) {
      this.form = await response.json();
    } else {
      this.$router.push({ name: "corrections" });
      this.$emit("close");
    }
    this.update_limit_from_choices()
    this.update_limit_to_choices()
  },
  methods: {
    async save() {
      const response = await fetch(this.source, {
        method: "post",
        body: JSON.stringify(this.form),
        headers: { "Content-Type": "application/json" },
        credentials: "include",
      });
      if (response.ok) {
        this.$emit("close");
      } else {
        this.formError = await response.json();
      }
    },
    async del(){
      if(confirm('Удалить?')){
        const response = await fetch(this.source, {
          method: "delete",
          credentials: "include",
        });
        if (response.ok) {
          this.$emit("close");
        }
      }
    },
    update_limit_from_choices: function(){
      if(this.form.org_from){
        fetch("/limits/list/?org=" + this.form.org_from)
          .then((response) => response.json())
          .then((data) => {
            this.limit_from_choices = data.results;
          });        
      }
    },
    update_limit_to_choices: function(){
      if(this.form.org_to){
        fetch("/limits/list/?org=" + this.form.org_to)
          .then((response) => response.json())
          .then((data) => {
            this.limit_to_choices = data.results;
          });        
      }
    }
  },
};
</script>
